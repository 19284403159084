@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

$breakpointMedium: 1025px;
$breakpointSmall: 400px;

$mainSessionWidth: 190px; // NOTE: Width of session cell
$mediumSessionWidth: 135px; // NOTE: Width of session cell
$minSessionWidth: 110px; // NOTE: Width of session cell

@keyframes pulseRight {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
}

@keyframes pulseLeft {
  0% {
    background-position: -135% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@keyframes pulseCenter {
  0% {
    background-color: light.$surface-primary-default;
  }
  50% {
    background-color: light.$surface-primary-disabled;
  }
  100% {
    background-color: light.$surface-primary-default;
  }
}

.loading {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &--left {
    > * {
      background: linear-gradient(90deg, light.$surface-primary-default 0%, light.$surface-primary-disabled 50%, light.$surface-primary-default 100%);
      animation: pulseLeft 1.5s ease-in-out infinite;
    }
  }

  &--right {
    > * {
      background: linear-gradient(90deg, light.$surface-primary-default 0%, light.$surface-primary-disabled 50%, light.$surface-primary-default 100%);
      animation: pulseRight 1.5s ease-in-out infinite;
    }
  }

  &--center {
    > * {
      animation: pulseCenter 1.5s ease-in-out infinite;
    }
  }

  &__header {
    background-size: 400% 400%;
    height: 28px;
    border-bottom: 1px solid light.$ge-divider-default;
    width: $mainSessionWidth;

    @media (max-width: $breakpointMedium) {
      width: $mediumSessionWidth;
    }

    @media (max-width: $breakpointSmall) {
      width: $minSessionWidth;
    }
  }

  &__session {
    background-size: 400% 400%;
    height: 50px;
    border-bottom: 1px solid light.$ge-divider-default;
    border-right: 1px solid light.$ge-divider-default;
    width: $mainSessionWidth;

    @media (max-width: $breakpointMedium) {
      width: $mediumSessionWidth;
    }

    @media (max-width: $breakpointSmall) {
      width: $minSessionWidth;
    }
  }
}
